import { Button } from "antd";
import React from "react";
import svg from "../../assets/success.svg";
import { useNavigate, useParams } from "react-router-dom";
function Success() {
  const { id } = useParams();
  const Navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        padding: "8rem",
      }}
    >
      {" "}
      <img src={svg} alt="404" height={500} width={500} />{" "}
      {id === "1" ? (
        <div>
          {" "}
          <p style={{ fontSize: "1.2vw" }}>
            Le capital à protéger déclaré étant supérieur ou égal à 50 000 €, ce
            dossier est soumis à un questionnaire de risque qui est adressé à
            votre client. <br />
            Nous vous remercions de le remplir avec le plus grand soin. <br />{" "}
            Dès réception du questionnaire complété, les équipes d'AS Solutions
            procéderons à l'analyse et la validation du risque sous 48 heures.{" "}
            <br />
            Nous restons à votre disposition pour tout complément d'information
            .
          </p>{" "}
        </div>
      ) : (
        <div>
          {" "}
          <p style={{ fontSize: "1.2vw" }}>
            Votre souscription est bien enregistrée et sera validée par les
            équipes AS Solutions dans les plus brefs délais, Ce dossier n'est
            pas soumis à déclaration du risque par le client.
          </p>{" "}
        </div>
      )}
      <Button
        onClick={() => {
          Navigate("/?partnership_id=" + localStorage.getItem("api_key"));
          window.location.reload();
        }}
      >
        Nouvelle souscription
      </Button>
    </div>
  );
}

export default Success;
