import { useContext, useEffect, useState } from "react";
import LogoAsSolutions from "../../assets/AsSolutions.png";
import classes from "./Home.module.css";
import {
  Steps,
  Form,
  FloatButton,
  notification,
  Spin,
  Button,
  ConfigProvider,
} from "antd";
import InfoPrincipale from "../../components/InfoPrincipale/InfoPrincipale";
import InfoComplementaire from "../../components/InfoComplementaire/InfoComplementaire";
import Tarifications from "../../components/Tarification/Tarifications";
import Documents from "../../components/Documents/Documents";
import GlobalContext from "../../contexts/GlobalContext";
import DevoirDeConseil from "../../components/DevoirDeConseil/DevoirDeConseil";
import dayjs from "dayjs";
import {
  CheckCircleOutlined,
  ClearOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import axios from "../../utils/axios";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import InformationBancaire from "../../components/InformationBancaire/InformationBancaire";
import iconSend from "../../assets/icon-send.png";
import template from "../../constants/template_devis.js";
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from "./ConfirmationPopup.jsx";
import JsonDrawer from "../../components/JsonDrawer.jsx";

const infosComplementaires = `.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
.ant-checkbox-inner {
border-color: #292371 !important;
padding     : 8px !important;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) .ant-checkbox-inner {
padding: 8px !important;
}

.ant-checkbox-wrapper: not(.ant-checkbox-wrapper-disabled): hover
.ant-checkbox-checked: not(.ant-checkbox-disabled)
.ant-checkbox-inner {
background-color: #292371 !important;
border-color    : #292371;
padding         : 8px !important;
}

.ant-checkbox-wrapper: not(.ant-checkbox-wrapper-disabled)
.ant-checkbox-checked: not(.ant-checkbox-disabled)
.ant-checkbox-inner {
background-color: #292371 !important;
border-color    : #292371;
padding         : 8px !important;
}
`;

const infosBancaires = ` .ant-radio-checked .ant-radio-inner{
  border-color: red !important ;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: #E30613 !important;
  padding: 1.3rem;
  top: 0.13rem !important;
  left: auto;
  right: -0.39rem !important;
  border-radius: 2rem;
}

.ant-radio:hover .ant-radio-inner {
  border-color: red ;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
  background-color: white !important;
  border          : 1px #FD2F2F solid;
  width           : 2rem !important;
  height          : 2rem !important;
}
.ant-radio-wrapper .ant-radio-inner{
  width : 2rem !important;
  height: 2rem !important;
  border: #8E8E8E solid 1px ;
}

.ant-radio-wrapper .ant-radio-inner::after{
  transition: none !important;
}
`;

function Home() {
  const {
    globalData,
    setGlobalData,
    docs,
    prime_commerciale_ttc,
    payementType,
    garantiesAutorise,
    activities,
    activitiesSecondaire,
    value,
    validRef,
    globalLoading,
    garanties_obligatoires,
    garanties_facultatives,
    current,
    setCurrent,
    setOppDataCompare,
    oppDataCompare,
    tarifRef,
  } = useContext(GlobalContext);

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [garantieOptions, setGarantieOptions] = useState(
    JSON.parse(sessionStorage.getItem("garantieOptions"))
      ? JSON.parse(sessionStorage.getItem("garantieOptions"))
      : {
          "PE suite à incendie ou DDE": true,
          "PE suite à Vol ou Vandalisme": true,
          "Perte de Valeur du Fonds": true,
          "Garantie Vol": true,
          "Marchandises en Frigo": true,
          "Garantie Bris de Machines": true,
          "Marchandises matériels transportés": true,
          "Dommages électriques": true,
          "Extension extérieurs": true,
          "Protection juridique": true,
        }
  );

  useEffect(() => {
    if (validRef) {
      form.setFieldsValue({
        NUM_SIRET_1: globalData?.NUM_SIRET_1,
        ...globalData,
        ...globalData?.garanties,
        date_effet: dayjs(globalData?.date_effet),
      });
      setGarantieOptions(globalData?.garanties);
    }
  }, [validRef, globalLoading]);

  useEffect(() => {
    sessionStorage.setItem("garantieOptions", JSON.stringify(garantieOptions));
  }, [garantieOptions]);

  useEffect(() => {
    form.setFieldsValue(
      JSON.parse(sessionStorage.getItem("globalData"))
        ? {
            ...JSON.parse(sessionStorage.getItem("globalData")),
            Pays: "France",
            date_effet: JSON.parse(sessionStorage.getItem("globalData"))
              ?.date_effet
              ? dayjs(
                  JSON.parse(sessionStorage.getItem("globalData")).date_effet
                )
              : dayjs().add(1, "day"),

            ...JSON.parse(sessionStorage.getItem("garantieOptions")),
          }
        : {
            ...form.getFieldsValue(),
            surface_locaux: "",
            Pays: "France",
            contenu_pro: "",
            frais_dossier: 0,
            code_activite_secondaire: "",
            franchise: 400,
            date_effet: dayjs().add(1, "day"),
            Fractionnement: "Mensuel",
            "Jour de prélèvement": 5,
          }
    );
  }, [globalLoading]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    sessionStorage.setItem("current", JSON.stringify(current));

    if (current === 4) {
      setDisabled(false);
    }
  }, [current]);

  const next = () => {
    let error = false;
    let errorMesssage = "";
    if (current === 0) {
      setOppDataCompare({ ...form.getFieldsValue() });
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...garantieOptions,
      });
      const departmentNumber = parseInt(
        form.getFieldsValue()["code_postal"].substring(0, 2),
        10
      );
      if (
        departmentNumber < 1 ||
        departmentNumber > 95 ||
        departmentNumber === 20
      ) {
        error = true;
        errorMesssage =
          "Nous sommes désolés, le produit ne correspond pas à votre besoin.";
      }
    }
    if (current === 1) {
      if (
        0 >
          form.getFieldsValue()[
            "Nombre de sinistres déclarés au cours des 36 derniers mois :"
          ] ||
        2 <
          form.getFieldsValue()[
            "Nombre de sinistres déclarés au cours des 36 derniers mois :"
          ] ||
        form.getFieldsValue()[
          "Nombre de sinistres déclarés au cours des 36 derniers mois Dont Vol :"
        ] >
          form.getFieldsValue()[
            "Nombre de sinistres déclarés au cours des 36 derniers mois :"
          ]
      ) {
        return notification.error({
          message: "Erreur",
          duration: 4,
          closable: true,
          description:
            "Désolé, votre demande de souscription au contrat MRP n'est pas éligible",
        });
      }

      Object.keys(form.getFieldsValue()).every((element) => {
        if (
          element !==
          "Le local est-il à minima, muni des moyens de protection suivants ?"
        ) {
          if (
            form.getFieldsValue()[element] == true &&
            !element.includes("Nombre de sinistres")
          ) {
            error = true;
            notification.error({
              message: "Erreur",
              duration: 4,
              closable: true,
              description:
                "Désolé, votre demande de souscription au contrat MRP n'est pas éligible.",
            });
            return false;
          }
        } else if (
          element ===
          "Le local est-il à minima, muni des moyens de protection suivants ?"
        ) {
          setGlobalData({
            ...globalData,
            garanties: {
              ...globalData?.garanties,
              "Garantie Vol": form.getFieldsValue()[element] || false,
            },
          });
          setGarantieOptions({
            ...garantieOptions,
            "Garantie Vol": form.getFieldsValue()[element] || false,
          });
          form.setFieldValue(
            "Garantie Vol",
            (globalData?.garanties["Garantie Vol"] &&
              form.getFieldsValue()[element]) ||
              false
          );
        }
        return true;
      });
    }
    if (current === 4) {
      Object.keys(docs).every((element) => {
        if (docs[element].length === 0) {
          error = true;
          return notification.error({
            message: "Erreur",
            duration: 4,
            closable: true,
            description:
              "Afin de poursuivre la souscription, veuillez importer tous les documents nécessaires.",
          });
        }
        return true;
      });
    }
    if (current === 2) {
      if(form.getFieldsValue()["qualiate_occupant"])
      {
        setOppDataCompare({
          ...oppDataCompare,
          QUALITE_OCCUPANT_2: form.getFieldsValue()["qualiate_occupant"],
        }
        );
      }
      setGlobalData({
        ...globalData,
        garanties: {
          ...globalData?.garanties,
          "Garantie Vol":
            form.getFieldsValue()[
              "Le local est-il à minima, muni des moyens de protection suivants ?"
            ] || false,
        },
        ...form.getFieldsValue(),
        capitaux_vol:
          garantieOptions["Garantie Vol"] && form.getFieldsValue()?.capitaux_vol
            ? parseFloat(form.getFieldsValue()?.capitaux_vol)
            : 0,
        capitaux_frigo: form.getFieldsValue()?.capitaux_frigo
          ? parseFloat(form.getFieldsValue()?.capitaux_frigo)
          : 0,
        capitaux_machine: form.getFieldsValue()?.capitaux_machine
          ? parseFloat(form.getFieldsValue()?.capitaux_machine)
          : 0,
        capitaux_transport: form.getFieldsValue()?.capitaux_transport
          ? parseFloat(form.getFieldsValue()?.capitaux_transport)
          : 0,
        capitaux_extension: form.getFieldsValue()?.capitaux_extension
          ? parseFloat(form.getFieldsValue()?.capitaux_extension)
          : 0,
        capitaux_dommage: form.getFieldsValue()?.capitaux_dommage
          ? parseFloat(form.getFieldsValue()?.capitaux_dommage)
          : 0,
      });
    } else {
      setGlobalData({
        ...globalData,
        ...form.getFieldsValue(),
        capitaux_vol:
          garantieOptions["Garantie Vol"] && globalData?.capitaux_vol
            ? globalData?.capitaux_vol
            : 0,
        capitaux_frigo:
          garantieOptions["Marchandises en Frigo"] && globalData?.capitaux_frigo
            ? globalData?.capitaux_frigo
            : 0,
        capitaux_machine:
          garantieOptions["Garantie Bris de Machines"] &&
          globalData?.capitaux_machine
            ? globalData?.capitaux_machine
            : 0,
        capitaux_transport:
          garantieOptions["Marchandises matériels transportés"] &&
          globalData?.capitaux_transport
            ? globalData?.capitaux_transport
            : 0,
        capitaux_extension:
          garantieOptions["Extension extérieurs"] &&
          globalData?.capitaux_extension
            ? globalData?.capitaux_extension
            : 0,
        capitaux_dommage:
          garantieOptions["Dommages électriques"] &&
          globalData?.capitaux_dommage
            ? globalData?.capitaux_dommage
            : 0,
      });
    }
    if (!error) {
      setCurrent(current + 1);
      notification.destroy();
    } else {
      if (errorMesssage)
        notification.error({
          message: "Erreur",
          duration: 4,
          closable: true,
          description: errorMesssage,
        });
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const sendEmail = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    const dateEffet = new Date(globalData["date_effet"]);
    dateEffet.setFullYear(dateEffet.getFullYear() + 1);
    dateEffet.setDate(dateEffet.getDate() - 1);
    let siret = globalData["NUM_SIRET_1"] || sessionStorage.getItem("siret");
    let Surface_des_locaux = String(globalData["surface_locaux"]);
    const id_opp = sessionStorage.getItem("id_opp");
    const data = {
      id_opp: id_opp,
      tarif_ref: tarifRef,
      prime_data: {
        code_naf: globalData["code_naf"],
        chiffre_affaire: globalData["chiffre_affaire"],
        commission_tarificateur_autres_gar: String(globalData["commission"]),
        garanties: {
          "PE suite à incendie ou DDE":
            globalData?.garanties &&
            globalData?.garanties["PE suite à incendie ou DDE"],
          "PE suite à Vol ou Vandalisme":
            globalData?.garanties &&
            globalData?.garanties["PE suite à Vol ou Vandalisme"],
          "Perte de Valeur du Fonds":
            globalData?.garanties &&
            globalData?.garanties["Perte de Valeur du Fonds"],
          "Garantie Vol":
            globalData?.garanties && globalData?.garanties["Garantie Vol"],
          "Marchandises en Frigo":
            globalData?.garanties &&
            globalData?.garanties["Marchandises en Frigo"],
          "Garantie Bris de Machines":
            globalData?.garanties &&
            globalData?.garanties["Garantie Bris de Machines"],
          "Marchandises matériels transportés":
            globalData?.garanties &&
            globalData?.garanties["Marchandises matériels transportés"],
          "Dommages électriques":
            globalData?.garanties &&
            globalData?.garanties["Dommages électriques"],
          "Extension extérieurs":
            globalData?.garanties &&
            globalData?.garanties["Extension extérieurs"],
          "Protection juridique":
            globalData?.garanties &&
            globalData?.garanties["Protection juridique"],
        },
        franchise: globalData["franchise"],
        code_activite_principale: globalData["code_activite_principale"],
        code_activite_secondaire: globalData["code_activite_secondaire"],
        capitaux_Degat_des_eaux: globalData["capitaux_Degat_des_eaux"],
        capitaux_bris_de_glace: globalData["capitaux_bris_de_glace"],
        surface_locaux: globalData["surface_locaux"],
        valeur_metre_carre: globalData["valeur_metre_carre"],
        contenu_pro: globalData["contenu_pro"],
        capitaux_vol: globalData["capitaux_vol"],
        capitaux_frigo: globalData["capitaux_frigo"],
        capitaux_machine: globalData["capitaux_machine"],
        capitaux_transport: globalData["capitaux_transport"],
        capitaux_extension: globalData["capitaux_extension"],
        capitaux_dommage: globalData["capitaux_dommage"],
      },
      data_fields: {
        BDG: "Souscrite",
        CA: String(globalData["chiffre_affaire"]),
        CN: "Souscrite",
        DDE: "Souscrite",
        DE:
          globalData?.garanties &&
          globalData?.garanties["Extension extérieurs"] === false
            ? "Non souscrite"
            : "Souscrite",
        FSE: "",
        PE: "Souscrite",
        PJ:
          globalData?.garanties &&
          globalData?.garanties["Protection juridique"] === false
            ? "Non souscrite"
            : "Souscrite",
        RC:
          globalData?.garanties &&
          globalData?.garanties["Dommages électriques"] === false
            ? "Non souscrite"
            : "Souscrite",
        Surface_des_locaux: `${Surface_des_locaux} m²`,
        TGNA: "Souscrite",
        TP:
          globalData?.garanties &&
          globalData?.garanties["Marchandises matériels transportés"] === false
            ? "Non souscrite"
            : "Souscrite",
        VOL:
          globalData?.garanties &&
          globalData?.garanties["PE suite à Vol ou Vandalisme"] === false
            ? "Non souscrite"
            : "Souscrite",
        valeur_venale:
          globalData?.garanties &&
          globalData?.garanties["Perte de Valeur du Fonds"] === false
            ? "Non souscrite"
            : "Souscrite",
        qualite_occ: globalData["QUALITE_OCCUPANT_2"],
        adresse: globalData["adresse_siege"],
        adresse_souscripteur: globalData["_adresse"],
        code_postale: globalData["code_postal"],
        date_effet: dayjs(globalData["date_effet"]).format("DD/MM/YYYY"),
        date_fin: dayjs(dateEffet).format("DD/MM/YYYY"),
        frais_dossier: String(globalData["frais_dossier"]),
        franchise: String(globalData["franchise"]),
        libelle_activite_principale: globalData["label_activite_principale"],
        libelle_activite_secondaire: globalData["code_activite_secondaire"],
        code_naf: globalData["label_naf"],
        representant: globalData["representant"],
        nom_prenom_souscripteur: globalData["nom"] + " " + globalData["prenom"],
        siret: siret || "",
        societe: globalData["raison_sociale"],
        date_souscription: dayjs().format("DD/MM/YYYY"),
        ville: globalData["ville"],
        code_postal_ville_souscripteur:globalData?._code_postal + ", "+ globalData?._ville
      },
    };

    var base64DataIpid = "";

    const dumpJson = JSON.stringify({
      globalData: globalData,
      id_opp: id_opp,
      garantiesAutorise: garantiesAutorise,
      prime_commerciale_ttc: prime_commerciale_ttc,
      activities: activities,
      activitiesSecondaire: activitiesSecondaire,
      value: value,
      garanties_facultatives: garanties_facultatives,
      garanties_obligatoires: garanties_obligatoires,
      submited_date: dayjs().format("DD/MM/YYYY"),
      step: current,
    });

    axios
      .post(
        import.meta.env.VITE_API_URL_AS + "/taux_fidelidade/generate_devis",
        data,
        {
          headers: {
            idSession: token,
          },
        }
      )
      .then((res) => {
        fetch(res?.data?.devis)
          .then((response) => response.blob())
          .then((blob) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            });
          })
          .then((result) => {
            // Extract the Base64 data from the result
            const base64Data = result.split(",")[1];
            let html = template.replace(
              "*|Prénom|* *|Nom|*",
              `${globalData["prenom"]} ${globalData["nom"]}`
            );
            if (globalData?.garanties["Protection juridique"]) {
              var doc_to_send = {
                "IPID_PJ_COMPLEMENT_MRP_AS_SOLUTIONS_V02_2023.pdf": import.meta
                  .env.VITE_API_IPID_PJ,
                "CG_PJ_COMPLEMENT_MRP_AS_SOLUTIONS_V02_2023.pdf": import.meta
                  .env.VITE_API_CG_PG,
              };
            } else {
              var doc_to_send = {};
            }
            doc_to_send = {
              ...doc_to_send,
              "IPID MRP.pdf": res?.data?.ipid,
              "MRC_CG_sans assistance 26092023.pdf": res?.data?.cg,
            };
            let docs_b64 = [];

            function fetchAndConvertToBase64(name, url) {
              return fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                  return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                  });
                })
                .then((result) => {
                  let b64 = result.split(",")[1];
                  return {
                    type: "b64",
                    name: name,
                    content: b64,
                  };
                });
            }

            Promise.all(
              Object.entries(doc_to_send).map(([name, url]) =>
                fetchAndConvertToBase64(name, url)
              )
            )
              .then((results) => {
                docs_b64 = results;
                const id_ref = uuidv4();
                // Save data to session.
                axios
                  .post(
                    `${
                      import.meta.env.VITE_API_URL_AS
                    }/session_url/set_object_from_session_url`,
                    {
                      objet: dumpJson,
                      id: id_ref,
                    }
                  )
                  .then((responseSession) => {
                    axios
                      .post(
                        `${
                          import.meta.env.VITE_API_URL_AS
                        }/mailchimp/send_mail`,
                        {
                          recipient: globalData["souscripteur_email"],
                          sender: "souscription@as-solutions.fr",
                          subject: "Devis",
                          body: "",
                          id_opp: id_opp,
                          url: window.location.href + "&ref=" + id_ref,
                          html: html,
                          attachments: [
                            {
                              type: "b64",
                              name: "Devis.pdf",
                              content: base64Data,
                            },
                            ...docs_b64,
                          ],
                        }
                      )
                      .then((res2) => {
                        setLoading(false);
                        notification.open({
                          message: "",
                          description: "E-mail envoyé avec succès ! ",
                          icon: (
                            <CheckCircleOutlined
                              style={{
                                color: "#62B7B2",
                              }}
                            />
                          ),
                        });
                        window.open(res?.data?.devis, "_blank");
                      })
                      .catch((err) => {
                        setLoading(false);
                      });
                  })
                  .catch((error) =>
                    notification.error({
                      message: "Erreur",
                      duration: 4,
                      closable: true,
                      description: error?.response?.data?.message,
                    })
                  );
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const downloadDevis = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    const dateEffet = new Date(globalData["date_effet"]);
    dateEffet.setFullYear(dateEffet.getFullYear() + 1);
    dateEffet.setDate(dateEffet.getDate() - 1);
    let siret = globalData["NUM_SIRET_1"] || sessionStorage.getItem("siret");
    let Surface_des_locaux = String(globalData["surface_locaux"]);
    const id_opp = sessionStorage.getItem("id_opp")
      ? sessionStorage.getItem("id_opp")
      : "";
    const data = {
      id_opp: id_opp,
      tarif_ref: tarifRef,
      prime_data: {
        code_naf: globalData["code_naf"],
        chiffre_affaire: globalData["chiffre_affaire"],
        commission_tarificateur_autres_gar: String(globalData["commission"]),
        garanties: {
          "PE suite à incendie ou DDE":
            globalData?.garanties &&
            globalData?.garanties["PE suite à incendie ou DDE"],
          "PE suite à Vol ou Vandalisme":
            globalData?.garanties &&
            globalData?.garanties["PE suite à Vol ou Vandalisme"],
          "Perte de Valeur du Fonds":
            globalData?.garanties &&
            globalData?.garanties["Perte de Valeur du Fonds"],
          "Garantie Vol":
            globalData?.garanties && globalData?.garanties["Garantie Vol"],
          "Marchandises en Frigo":
            globalData?.garanties &&
            globalData?.garanties["Marchandises en Frigo"],
          "Garantie Bris de Machines":
            globalData?.garanties &&
            globalData?.garanties["Garantie Bris de Machines"],
          "Marchandises matériels transportés":
            globalData?.garanties &&
            globalData?.garanties["Marchandises matériels transportés"],
          "Dommages électriques":
            globalData?.garanties &&
            globalData?.garanties["Dommages électriques"],
          "Extension extérieurs":
            globalData?.garanties &&
            globalData?.garanties["Extension extérieurs"],
          "Protection juridique":
            globalData?.garanties &&
            globalData?.garanties["Protection juridique"],
        },
        franchise: globalData["franchise"],
        code_activite_principale: globalData["code_activite_principale"],
        code_activite_secondaire: globalData["code_activite_secondaire"],
        capitaux_Degat_des_eaux: globalData["capitaux_Degat_des_eaux"],
        capitaux_bris_de_glace: globalData["capitaux_bris_de_glace"],
        surface_locaux: globalData["surface_locaux"],
        valeur_metre_carre: globalData["valeur_metre_carre"],
        contenu_pro: globalData["contenu_pro"],
        capitaux_vol: globalData["capitaux_vol"],
        capitaux_frigo: globalData["capitaux_frigo"],
        capitaux_machine: globalData["capitaux_machine"],
        capitaux_transport: globalData["capitaux_transport"],
        capitaux_extension: globalData["capitaux_extension"],
        capitaux_dommage: globalData["capitaux_dommage"],
      },
      data_fields: {
        BDG: "Souscrite",
        CA: String(globalData["chiffre_affaire"]),
        CN: "Souscrite",
        DDE: "Souscrite",
        DE:
          globalData?.garanties &&
          globalData?.garanties["Extension extérieurs"] === false
            ? "Non souscrite"
            : "Souscrite",
        FSE: "",
        PE: "Souscrite",
        PJ:
          globalData?.garanties &&
          globalData?.garanties["Protection juridique"] === false
            ? "Non souscrite"
            : "Souscrite",
        RC:
          globalData?.garanties &&
          globalData?.garanties["Dommages électriques"] === false
            ? "Non souscrite"
            : "Souscrite",
        Surface_des_locaux: `${Surface_des_locaux} m²`,
        TGNA: "Souscrite",
        TP:
          globalData?.garanties &&
          globalData?.garanties["Marchandises matériels transportés"] === false
            ? "Non souscrite"
            : "Souscrite",
        VOL:
          globalData?.garanties &&
          globalData?.garanties["PE suite à Vol ou Vandalisme"] === false
            ? "Non souscrite"
            : "Souscrite",
        valeur_venale:
          globalData?.garanties &&
          globalData?.garanties["Perte de Valeur du Fonds"] === false
            ? "Non souscrite"
            : "Souscrite",
        qualite_occ: globalData["QUALITE_OCCUPANT_2"],
        adresse: globalData["adresse_siege"],
        adresse_souscripteur: globalData["_adresse"],
        code_postale: globalData["code_postal"],
        date_effet: dayjs(globalData["date_effet"]).format("DD/MM/YYYY"),
        date_fin: dayjs(dateEffet).format("DD/MM/YYYY"),
        frais_dossier: String(globalData["frais_dossier"]),
        franchise: String(globalData["franchise"]),
        libelle_activite_principale: globalData["label_activite_principale"],
        libelle_activite_secondaire: globalData["code_activite_secondaire"],
        code_naf: globalData["label_naf"],
        representant: globalData["representant"],
        siret: siret || "",
        societe: globalData["raison_sociale"],
        date_souscription: dayjs().format("DD/MM/YYYY"),
        ville: globalData["ville"],
        code_postal_ville_souscripteur:globalData?._code_postal + ", "+ globalData?._ville
      },
    };
    axios
      .post(
        import.meta.env.VITE_API_URL_AS + "/taux_fidelidade/generate_devis",
        data,
        {
          headers: {
            idSession: token,
          },
        }
      )
      .then((res) => {
        const pdfUrl = res?.data?.devis;
        if (pdfUrl) {
          fetch(pdfUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "Devis.pdf");
              document.body.appendChild(link);
              link.click();
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        return error;
      });
  };

  const steps = [
    {
      title: "Informations principales",
      content: (
        <InfoPrincipale form={form} setGarantieOptions={setGarantieOptions} />
      ),
    },
    // {
    //   title: "Les ",
    //   content: (
    //     <Couvertures
    //       form={form}
    //       prev={prev}
    //       garantieOptions={garantieOptions}
    //       setGarantieOptions={setGarantieOptions}
    //     />
    //   ),
    // },
    {
      title: "Éléments de validation du risque",
      content: (
        <DevoirDeConseil
          prev={prev}
          form={form}
          garantieOptions={garantieOptions}
          setGarantieOptions={setGarantieOptions}
        />
      ),
    },
    {
      title: "Couvertures et informations complémentaires",
      content: <InfoComplementaire prev={prev} form={form} />,
    },

    {
      title: "Tarifications",
      content: (
        <Tarifications
          prev={prev}
          garantieOptions={garantieOptions}
          setGarantieOptions={setGarantieOptions}
          form={form}
          setDisabled={setDisabled}
          disabled={disabled}
        />
      ),
    },

    {
      title: "Documents",
      content: <Documents prev={prev} />,
    },
    {
      title: "Paiement",
      content: <InformationBancaire prev={prev} form={form} />,
    },
  ];

  const onChange = (formValues) => {
    if (current === 2) {
      if (form.getFieldsValue()["capital_protege"]) {
        const garanties = {
          "PE suite à incendie ou DDE":
            form.getFieldsValue()["PE suite à incendie ou DDE"] || false,
          "PE suite à Vol ou Vandalisme":
            form.getFieldsValue()["PE suite à Vol ou Vandalisme"] || false,
          "Perte de Valeur du Fonds":
            form.getFieldsValue()["Perte de Valeur du Fonds"] || false,
          "Garantie Vol": form.getFieldsValue()["Garantie Vol"] || false,
          "Marchandises en Frigo":
            form.getFieldsValue()["Marchandises en Frigo"] || false,
          "Garantie Bris de Machines":
            form.getFieldsValue()["Garantie Bris de Machines"] || false,
          "Marchandises matériels transportés":
            form.getFieldsValue()["Marchandises matériels transportés"] ||
            false,
          "Dommages électriques":
            form.getFieldsValue()["Dommages électriques"] || false,
          "Extension extérieurs":
            form.getFieldsValue()["Extension extérieurs"] || false,
          "Protection juridique":
            form.getFieldsValue()["Protection juridique"] || false,
        };
        setGarantieOptions(garanties);
      }
    }
    if (current === 3) {
      const garanties = {
        "PE suite à incendie ou DDE":
          form.getFieldsValue()["PE suite à incendie ou DDE"] || false,
        "PE suite à Vol ou Vandalisme":
          form.getFieldsValue()["PE suite à Vol ou Vandalisme"] || false,
        "Perte de Valeur du Fonds":
          form.getFieldsValue()["Perte de Valeur du Fonds"] || false,
        "Garantie Vol": form.getFieldsValue()["Garantie Vol"] || false,
        "Marchandises en Frigo":
          form.getFieldsValue()["Marchandises en Frigo"] || false,
        "Garantie Bris de Machines":
          form.getFieldsValue()["Garantie Bris de Machines"] || false,
        "Marchandises matériels transportés":
          form.getFieldsValue()["Marchandises matériels transportés"] || false,
        "Dommages électriques":
          form.getFieldsValue()["Dommages électriques"] || false,
        "Extension extérieurs":
          form.getFieldsValue()["Extension extérieurs"] || false,
        "Protection juridique":
          form.getFieldsValue()["Protection juridique"] || false,
      };

      setGarantieOptions(garanties);
    }
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const handleSouscription = () => {
    form
      .validateFields()
      .then((promise) => {
        setLoading(true);
        const id_opp = sessionStorage.getItem("id_opp");
        const id_pros = sessionStorage.getItem("id_pros");
        const data = {
          id_opp: id_opp,
          id_pros: id_pros,
          type_payment: payementType == "Direct Debit" ? "sepa" : "card",
          tarif_ref: tarifRef,
          customer_data: {
            siren: globalData.siren,
            RS: globalData.raison_sociale,
            NUM_SIRET_1: globalData.NUM_SIRET_1,
            nom_personne_physique: globalData.nom,
            // HARD CODED
            MONTANT_CONTENU_1: 0,
            representant: globalData["representant"],
            prenom_personne_physique: globalData.prenom,
            codepostal_souscripteur: globalData._code_postal,
            commune_souscripteur: globalData._ville,
            ville: globalData.ville,
            // HARD CODED
            FORMEJURIDIQUE_2: globalData["form_juridique"],
            CP: globalData.code_postal,
            nom1_souscripteur: globalData.nom,
            nom_physique: globalData.nom,
            prenom_personnsique: globalData.prenom,
            ligne_adresse_souscripteur: globalData._adresse,
            streetName: globalData._adresse,
            // HARD CODED
            DN: "1999-10-10",
            // HARD CODED
            civilite: "Mr",
            name: globalData.nom,
            surname: globalData.prenom,
            // HARD CODED
            adresse_mail: globalData?.souscripteur_email,
            // HARD CODED
            NB_SINISTRES_1:
              globalData[
                "Nombre de sinistres déclarés au cours des 36 derniers mois :"
              ],
            téléphone: "+33" + globalData?.téléphone,
            télecopie: globalData?.télecopie,
            type_payment: payementType === "Direct Debit" ? "sepa" : "card",
            bic_swift_remboursement: form.getFieldsValue()["BIC remb"]
              ? form.getFieldsValue()["BIC remb"]
              : form.getFieldsValue()["BIC prev"],
            bic_swift_prelevement: form.getFieldsValue()["BIC prev"],
            iban_remboursement: form.getFieldsValue()["IBAN remb"]
              ? form.getFieldsValue()["IBAN remb"]
              : form.getFieldsValue()["IBAN prev"],
            iban_prelevement: form.getFieldsValue()["IBAN prev"],
            titulaire_compte_remboursement: form.getFieldsValue()[
              "Titulaire du compte remb"
            ]
              ? form.getFieldsValue()["Titulaire du compte remb"]
              : form.getFieldsValue()["Titulaire du compte prev"],
            nom_banque_remboursement: form.getFieldsValue()[
              "Nom de la banque remb"
            ]
              ? form.getFieldsValue()["Nom de la banque remb"]
              : form.getFieldsValue()["Nom de la banque prev"],
            nom_banque_prelevement:
              form.getFieldsValue()["Nom de la banque prev"],
            titulaire_compte_prelevement:
              form.getFieldsValue()["Titulaire du compte prev"],
            rcs: globalData?.rcs,
            en_qualite_de: globalData?.en_qualite_de,
            adresse_risque: globalData?.adresse_siege,
            complement_adresse: globalData?.complement_adresse,
            qualite_occ: globalData?.QUALITE_OCCUPANT_2,
            NB_SINISTRES_VOL_1:
              globalData[
                "Nombre de sinistres déclarés au cours des 36 derniers mois Dont Vol :"
              ],
          },
          parcours_data: {
            ...globalData,
            protection_response:
              globalData[
                "Le local est-il à minima, muni des moyens de protection suivants ?"
              ],
            entreprise_creation_date: sessionStorage.getItem(
              "entreprise_creation_date"
            ),
            "J’accepte de recevoir les offres commerciales personnalisées distribuées par mon courtier.":
              form.getFieldValue()[
                "J’accepte de recevoir les offres commerciales personnalisées distribuées par mon courtier."
              ],
            Fractionnement: form.getFieldValue()["Fractionnement"],
            "Jour de prélèvement": form.getFieldValue()["Jour de prélèvement"],
            commission_tarificateur_autres_gar: String(globalData?.commission),
            date_effet: dayjs(globalData.date_effet).format("DD/MM/YYYY"),
            date_souscription: dayjs().format("DD/MM/YYYY"),
            code_act_fact: globalData?.code_act_fact,
            NUM_SIRET_1: form.getFieldValue()["NUM_SIRET_1"],
          },
        };
        const URL = import.meta.env.VITE_API_URL_AS;
        const geoprod_url = import.meta.env.VITE_API_GEOPROD_URL;

        axios
          .post(URL + "/taux_fidelidade/souscription_mrp", data)
          .then((response) => {
            if (response.data.id_affaire) {
              let token = localStorage.getItem("tokenRobot");
              const kbis = import.meta.env.VITE_API_KBIS_ID;
              const bail = import.meta.env.VITE_API_BAIL_ID;
              const pip = import.meta.env.VITE_API_PIP_ID;
              var counter = 0;
              Object.keys(docs).forEach((doc, index) => {
                if (docs[doc][0]?.originFileObj) {
                  const formfile = new FormData();
                  formfile.append(
                    "id_type_doc",
                    doc == "KBIS"
                      ? kbis
                      : doc == "BAIL"
                      ? bail
                      : doc == "PIP"
                      ? pip
                      : 138
                  );
                  formfile.append("id_affaire", response.data.id_affaire);
                  formfile.append(
                    "file[]",
                    docs[doc][0]?.originFileObj,
                    docs[doc][0]?.name
                  );

                  axios
                    .post(geoprod_url + "upload_document_affaire", formfile, {
                      headers: {
                        idSession: token,
                      },
                    })
                    .then((res) => {
                      counter = counter + 1;
                      if (counter == 3) {
                        setLoading(false);
                        sessionStorage.clear();
                        window.location =
                          globalData?.capital_protege > 50000
                            ? "/success/1"
                            : "/success/2";
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                    });
                } else {
                  counter = counter + 1;
                }
              });
            } else {
              setLoading(false);
              notification.error({
                message: "Erreur",
                duration: 4,
                closable: true,
                description: response?.data?.message,
              });
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
        notification.error({
          message: "Erreur",
          duration: 4,
          closable: true,
          description: "Veuillez vérifier tous les champs.",
        });
      });
  };

  return globalLoading ? (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <div style={{ paddingBottom: "5rem" }}>
      {current === 3 && <style jsx="true">{infosComplementaires}</style>}
      {current === 6 && <style jsx="true"> {infosBancaires}</style>}
      <div className={classes.topBar}>
        <img
          src={LogoAsSolutions}
          alt="logo AsSolutions"
          className={classes.logoStyle}
        />
      </div>
      {loading && (
        <div className={classes.loaderContainer}>
          <h2 style={{ color: "white" }}>Veuillez patienter un instant</h2>

          <CustomLoader />
        </div>
      )}

      <Form
        form={form}
        layout="vertical"
        onFinishFailed={() => {
          notification.error({
            message: "Erreur",
            duration: 4,
            closable: true,
            description: "Veuillez vérifier tous les champs.",
          });
        }}
        onFinish={() => {
          next();
        }}
        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
        onValuesChange={onChange}
        className={classes.stepperContainer}
      >
        <Steps
          labelPlacement="vertical"
          className={classes.stepper}
          current={current}
          onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
          items={items}
        />
        <div className={classes.contentStyle}>{steps[current].content}</div>
        <div className={classes.holder}>
          <div className={classes.btnsHolder}>
            {current === steps.length - 1 && (
              <button
                type="button"
                className={classes.btnNext}
                onClick={handleSouscription}
                id="soumettre_souscription"
              >
                Soumettre la souscription
              </button>
            )}
            {current > 0 && current < steps.length - 1 && (
              <button
                className={disabled ? classes.btnPrevDisabled : classes.btnPrev}
                disabled={disabled}
                type="button"
                onClick={() => prev()}
                id="retour"
              >
                Retour
              </button>
            )}
            {current === 3 && (
              <div style={{ display: "flex" }}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#5ab6b1",
                      colorInfo: "#5ab6b1",
                    },
                  }}
                >
                  <Button
                    size="large"
                    onClick={sendEmail}
                    disabled={disabled}
                    style={{
                      height: "100%",
                      marginLeft: "15px",
                      marginRight: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    icon={
                      <img src={iconSend} style={{ marginRight: "15px" }} />
                    }
                    id="envoyer_devis_email"
                  >
                    Envoyer devis par E-mail
                  </Button>
                </ConfigProvider>
                <Button
                  disabled={disabled}
                  onClick={downloadDevis}
                  size="large"
                  style={{
                    height: "100%",
                    marginRight: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  icon={<DownloadOutlined style={{ fontSize: "30px" }} />}
                  id="telecharger_devis"
                >
                  <span style={{ textAlign: "center" }}>Télécharger devis</span>
                </Button>
              </div>
            )}
            {current < steps.length - 1 && (
              <button
                className={disabled ? classes.btnPrevDisabled : classes.btnNext}
                type={"submit"}
                disabled={disabled}
                id="suivant"
              >
                {current !== 2 ? "Suivant" : "Calculer"}
              </button>
            )}
          </div>
        </div>
      </Form>
      <div id="remise_zero">
        <FloatButton
          type="primary"
          icon={<ClearOutlined />}
          tooltip={<div>Remise à zero</div>}
          onClick={() => {
            sessionStorage.clear();
            window.location.reload();
          }}
        />
      </div>
      {/* <JsonDrawer form={form} garantieOptions={garantieOptions} /> */}
    </div>
  );
}

export default Home;
