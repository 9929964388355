import {
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Form,
  InputNumber,
  notification,
  Row,
  Switch,
  Tag,
} from "antd";
import React, { useState } from "react";
import { FormHeader } from "../FormHeader/FormHeader";
import classes from "./DevoirDeConseil.module.css";

import PopoverInfo from "./PopoverInfo";
import { useContext } from "react";
import GlobalContext from "../../contexts/GlobalContext";

function DevoirDeConseil(props) {
  const { prev, form, garantieOptions, setGarantieOptions } = props;
  const { globalData, setGlobalData, garantiesAutorise, setGarantiesAutorise } =
    useContext(GlobalContext);

  const [exam, setExam] = useState({
    "Avez-vous fait l’objet d’une résiliation pour non-paiement de cotisation par un précédent assureur au cours des 36 derniers mois ?": false,
    "Avez-vous fait l’objet d’une résiliation pour sinistres par un précédent assureur au cours des 36 derniers mois ?": false,
    "Avez-vous fait l’objet d’une résiliation pour fausse déclaration (ou nullité du contrat) par un précédent assureur au cours des 36 derniers mois ?": false,
    "Etes-vous sous le coup d’une liquidation ou d’un redressement judiciaire ?": false,
    "Le local et les dépendances sont-ils construits et couvert à moins de 75% en matériaux durs ?": false,
    "Le bâtiment dans lequel le local se situe est-il inoccupé ou désaffecté ?  ": false,
    "Le sommet des marchandises entreposées excède-t-il une hauteur de 7.20 mètres prise à partir du sol ?  ": false,
    "Le local est-il classé ou inscrit au titre des Monuments historiques, ou situés dans de tels bâtiments ?": false,
    "Le local est-il situé dans un centre commercial dont la superficie est supérieure à 3000 m2 ?  ": false,

    Discothèque: form.getFieldsValue(true)["Discothèque"] || false,
    "Boîte de nuit": form.getFieldsValue(true)["Boîte de nuit"] || false,
    Bowling: form.getFieldsValue(true)["Bowling"] || false,
    Cabaret: form.getFieldsValue(true)["Cabaret"] || false,
    "Bar de nuit": form.getFieldsValue(true)["Bar de nuit"] || false,
    "Tout autre établissement uniquement ouvert la nuit":
      form.getFieldsValue(true)[
        "Tout autre établissement uniquement ouvert la nuit"
      ] || false,

    "Application de peintures et de vernis":
      form.getFieldsValue(true)["Application de peintures et de vernis"] ||
      false,
    "Transformation de matières plastiques alvéolaires":
      form.getFieldsValue(true)[
        "Transformation de matières plastiques alvéolaires"
      ] || false,
    "des stocks d’emballages combustibles vides pour un montant supérieur à 15 000 € ou 30 000 € commerce de gros.":
      form.getFieldsValue(true)[
        "des stocks d’emballages combustibles vides pour un montant supérieur à 15 000 € ou 30 000 € commerce de gros."
      ] || false,
  });

  const table = [
    {
      Input: "Nombre de sinistres déclarés au cours des 36 derniers mois :",
    },
    {
      Input:
        "Nombre de sinistres déclarés au cours des 36 derniers mois Dont Vol :",
    },
    {
      YesOrNoQuestion:
        "Avez-vous fait l’objet d’une résiliation pour non-paiement de cotisation par un précédent assureur au cours des 36 derniers mois ?",
    },
    {
      YesOrNoQuestion:
        "Avez-vous fait l’objet d’une résiliation pour sinistres par un précédent assureur au cours des 36 derniers mois ?",
    },
    {
      YesOrNoQuestion:
        "Avez-vous fait l’objet d’une résiliation pour fausse déclaration (ou nullité du contrat) par un précédent assureur au cours des 36 derniers mois ?",
    },
    {
      YesOrNoQuestion:
        "Etes-vous sous le coup d’une liquidation ou d’un redressement judiciaire ?",
    },
    {
      YesOrNoQuestion:
        "Le local et les dépendances sont-ils construits et couvert à moins de 75% en matériaux durs ?",
    },
    {
      YesOrNoQuestion:
        "Le local est-il à minima, muni des moyens de protection suivants ?",
    },
    {
      YesOrNoQuestion:
        "Le bâtiment dans lequel le local se situe est-il inoccupé ou désaffecté ?  ",
    },
    {
      MultipleAnswers: "Le local est-il situé dans un bâtiment renfermant:",
      propositions: {
        rs: [
          "Discothèque",
          "Boîte de nuit",
          "Bowling",
          "Cabaret",
          "Bar de nuit",
          "Tout autre établissement uniquement ouvert la nuit",
        ],
        as: [
          "Application de peintures et de vernis",
          "Transformation de matières plastiques alvéolaires",
        ],
        YesOrNoQuestion:
          "Des stocks d’emballages combustibles vides pour un montant supérieur à 15 000 € ou 30 000 € commerce de gros.",
      },
    },
    {
      YesOrNoQuestion:
        "Le sommet des marchandises entreposées excède-t-il une hauteur de 7.20 mètres prise à partir du sol ?  ",
    },
    {
      YesOrNoQuestion:
        "Le local est-il classé ou inscrit au titre des Monuments historiques, ou situés dans de tels bâtiments ?",
    },
    {
      YesOrNoQuestion:
        "Le local est-il situé dans un centre commercial dont la superficie est supérieure à 3000 m2 ?  ",
    },
  ];

  return (
    <div>
      <FormHeader
        title="Éléments de validation du risque"
        number="2"
        prev={prev}
      />
      {table.map((element, index) => {
        if (element?.YesOrNoQuestion) {
          return (
            <ConfigProvider
              theme={{
                components: {
                  Form: {
                    margin: 0,
                    marginLG: 0,
                    marginXS: 0,
                  },
                },
              }}
              key={index}
            >
              <Row gutter={24} className={classes.rowContainer} key={index}>
                <Col
                  md={16}
                  xs={24}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h4>
                    {element.YesOrNoQuestion}
                    {element?.YesOrNoQuestion ===
                      "Le local est-il à minima, muni des moyens de protection suivants ?" && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "18px",
                          marginRight: 30,
                        }}
                      >
                        *
                      </span>
                    )}
                    {element?.YesOrNoQuestion ===
                      "Le local est-il à minima, muni des moyens de protection suivants ?" && (
                      <div id="moyen_protection_tooltip">
                        <PopoverInfo />
                      </div>
                    )}
                    <br />
                    {element?.YesOrNoQuestion ===
                      "Le local est-il à minima, muni des moyens de protection suivants ?" && (
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: "0.8rem",
                          fontStyle: "italic",
                          color: "#7b4343",
                        }}
                      >
                        <span
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginRight: 5,
                          }}
                        >
                          *
                        </span>
                        En cas d’absence de moyens de protections, la garantie
                        vol ne sera pas acquise.
                      </div>
                    )}
                  </h4>
                </Col>

                <Col lg={4} md={12} xs={24}>
                  <Form.Item
                    name={element?.YesOrNoQuestion}
                    id={element?.YesOrNoQuestion}
                    valuePropName="checked"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Switch
                      size="default"
                      checkedChildren={"Oui"}
                      unCheckedChildren={"Non"}
                      defaultChecked={form.getFieldValue(
                        element?.YesOrNoQuestion
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ConfigProvider>
          );
        } else if (element?.Input) {
          return (
            <Row gutter={24} className={classes.rowContainer} key={index}>
              <Col md={16} xs={24}>
                <h4>{element.Input}</h4>
              </Col>
              <Col lg={4} md={12} xs={24}>
                <Form.Item
                  name={element?.Input}
                  rules={[
                    {
                      required: true,
                      message: "Veuillez vérifier ce champ",
                    },
                  ]}
                  validateTrigger="onBlur"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    maxLength={3}
                    controls={false}
                    onKeyPress={(event) => {
                      if (event.key === "." || event.key === ",") {
                        event.preventDefault();
                      } else if (event.which < 48 || event.which > 57) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        } else {
          return (
            <div className={classes.container} key={index}>
              <h3>{element?.MultipleAnswers}</h3>

              <div className={classes.propContainer}>
                <div className={classes.qualityBlock}>
                  <h3 style={{ fontWeight: "bold" }}>Les risques suivants :</h3>
                  <Row gutter={24} className={classes.propBox}>
                    {element?.propositions?.rs.map((select, index) => {
                      return (
                        <Col
                          lg={8}
                          key={index}
                          style={{
                            height: "1.5rem",
                            padding: "0 3rem",
                            margin: ".3rem 0",
                          }}
                        >
                          <Form.Item name={select}>
                            <Checkbox
                              name={select}
                              onChange={(e) => {
                                form.setFieldsValue({
                                  ...form.getFieldsValue(),
                                  [e.target.name]:
                                    e.target.checked !== false
                                      ? e.target.checked
                                      : undefined,
                                });
                                setExam({
                                  ...exam,
                                  [e.target.name]:
                                    e.target.checked !== false
                                      ? e.target.checked
                                      : undefined,
                                });
                              }}
                              checked={exam[select]}
                            >
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  // textTransform: "capitalize",
                                }}
                              >
                                {select}
                              </span>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
                <div className={classes.qualityBlock}>
                  <h3 style={{ fontWeight: "bold" }}>
                    Les activités suivantes :
                  </h3>

                  <Row
                    gutter={24}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {element?.propositions?.as.map((select, index) => {
                      return (
                        <Col
                          key={index}
                          lg={12}
                          style={{
                            height: "1.5rem",
                            padding: "0 3rem",
                            margin: ".6rem 0",
                          }}
                        >
                          <Form.Item name={select}>
                            <Checkbox
                              name={select}
                              onChange={(e) => {
                                form.setFieldsValue({
                                  ...form.getFieldsValue(),
                                  [e.target.name]:
                                    e.target.checked !== false
                                      ? e.target.checked
                                      : undefined,
                                });
                                setExam({
                                  ...exam,
                                  [e.target.name]:
                                    e.target.checked !== false
                                      ? e.target.checked
                                      : undefined,
                                });
                              }}
                              checked={exam[select]}
                            >
                              <span
                                style={{
                                  fontWeight: 600,
                                  fontSize: "16px",
                                  // textTransform: "capitalize",
                                }}
                              >
                                {select}
                              </span>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
                <ConfigProvider
                  theme={{
                    components: {
                      Form: {
                        margin: 0,
                        marginLG: 0,
                        marginXS: 0,
                      },
                    },
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      margin: "1rem",
                    }}
                    key={index}
                  >
                    <Col
                      lg={20}
                      md={12}
                      xs={24}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <h4>{element?.propositions?.YesOrNoQuestion} </h4>
                    </Col>
                    <Col
                      lg={3}
                      md={12}
                      xs={24}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Form.Item
                        name={element?.propositions?.YesOrNoQuestion}
                        valuePropName="checked"
                      >
                        <Switch
                          size="default"
                          checkedChildren={"Oui"}
                          unCheckedChildren={"Non"}
                          defaultChecked={form.getFieldValue(
                            element?.propositions?.YesOrNoQuestion
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </ConfigProvider>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default DevoirDeConseil;
