import dayjs from "dayjs";
import { useState, useEffect } from "react";
import GlobalContext from "./GlobalContext";
import axios from "../utils/axios";
import { notification } from "antd";
import { set } from "lodash";

const AppContext = (props) => {
  const [garanties_obligatoires, setGaranties_obligatoires] = useState([]);
  const [garanties_facultatives, setGaranties_facultatives] = useState([]);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [activitiesSecondaire, setActivitiesSecondaire] = useState([]);
  const [value, setValue] = useState("Propriétaire Occupant");
  const [initValues, setInitValues] = useState({});
  const [oppDataCompare, setOppDataCompare] = useState({});
  const [oppData, setOppData] = useState({});
  const [tarifRef, setTarifRef] = useState();
  const [garantiesAutorise, setGarantiesAutorise] = useState(
    JSON.parse(sessionStorage.getItem("garantiesAutorise"))
      ? JSON.parse(sessionStorage.getItem("garantiesAutorise"))
      : {
          CatNat: true,
          "Dommages électriques": true,
          "Extension extérieurs": true,
          "Garantie Assistance": true,
          "Garantie BdG": true,
          "Garantie Bris de Machines": true,
          "Garantie DDE": true,
          "Garantie Vol": false,
          Incendie: true,
          "Marchandises en Frigo": true,
          "Marchandises matériels transportés": true,
          "PE suite à Vol ou Vandalisme": true,
          "PE suite à incendie ou DDE": true,
          "Perte de Valeur du Fonds": true,
          "Protection juridique": true,
          "RC exploitation": true,
          "RC professionnelle": true,
          TGNA: true,
        }
  );
  const [prime_commerciale_ttc, setPrime_commerciale_ttc] = useState({
    prime_commerciale_ttc: 0,
    prime_commerciale_ttc_mensuel: 12,
  });
  const [globalData, setGlobalData] = useState(
    JSON.parse(sessionStorage.getItem("globalData"))
      ? JSON.parse(sessionStorage.getItem("globalData"))
      : {
          franchise: 400,
          commission: 15,
          "Avez-vous fait l’objet d’unvalue, setValuee résiliation pour non-paiement de cotisation par un précédent assureur au cours des 36 derniers mois ?": false,
          "Avez-vous fait l’objet d’une résiliation pour sinistres par un précédent assureur au cours des 36 derniers mois ?": false,
          "Avez-vous fait l’objet d’une résiliation pour fausse déclaration (ou nullité du contrat) par un précédent assureur au cours des 36 derniers mois ?": false,
          "Etes-vous sous le coup d’une liquidation ou d’un redressement judiciaire ?": false,
          "Le local et les dépendances sont-ils construits et couvert à moins de 75% en matériaux durs ?": false,
          "Le bâtiment dans lequel le local se situe est-il inoccupé ou désaffecté ?  ": false,
          "Le sommet des marchandises entreposées excède-t-il une hauteur de 7.20 mètres prise à partir du sol ?  ": false,
          "Le local est-il classé ou inscrit au titre des Monuments historiques, ou situés dans de tels bâtiments ?": false,
          "Le local est-il situé dans un centre commercial dont la superficie est supérieure à 3000 m2 ?  ": false,
          Discothèque: false,
          "Boîte de nuit": false,
          Bowling: false,
          Cabaret: false,
          "Bar de nuit": false,
          "Tout autre établissement uniquement ouvert la nuit": false,
          "Application de peintures et de vernis": false,
          "Transformation de matières plastiques alvéolaires": false,
          "des stocks d’emballages combustibles vides pour un montant supérieur à 15 000 € ou 30 000 € commerce de gros.": false,
          promises: {},
          date_effet: dayjs().add(1, "day"),
          QUALITE_OCCUPANT_2: "Propriétaire Occupant",
          NUM_SIRET_1: "",
          Fractionnement: "Mensuel",
          "Jour de prélèvement": 5,
        }
  );
  const [current, setCurrent] = useState(
    JSON.parse(sessionStorage.getItem("current"))
      ? JSON.parse(sessionStorage.getItem("current"))
      : 0
  );

  const [initGaranties, setInitGaranties] = useState({});
  const [changed, setChanged] = useState(false);
  const [docs, setDocs] = useState({
    KBIS: [],
    BAIL: [],
    PIP: [],
  });
  const [validRef, setValidRef] = useState(false);

  const [payementType, setPayementType] = useState("Direct Debit");

  useEffect(() => {
    sessionStorage.setItem("globalData", JSON.stringify(globalData));
  }, [globalData]);

  useEffect(() => {
    sessionStorage.setItem(
      "garantiesAutorise",
      JSON.stringify(garantiesAutorise)
    );
  }, [garantiesAutorise]);

  // Get the current URL
  const url = new URL(window.location.href);

  // Get the query parameters
  const searchParams = new URLSearchParams(url.search);
  const ref = searchParams?.get("ref")?.split("/")[0];
  const partnership_id = new URLSearchParams(window.location.search).get(
    "partnership_id"
  );

  useEffect(() => {
    setGlobalLoading(true);

    if (window.location.pathname === "/") {
      if (partnership_id) {
        let config = {
          headers: {
            Authorization: `Bearer ${partnership_id}`,
          },
        };
        axios
          .post(`${import.meta.env.VITE_API_GEOPROD_URL}login`, {}, {
            headers: {
              Authorization: `Bearer ${import.meta.env.VITE_API_KEY_ROBOT_GEOPROD}`,
            },
          }).then((res)=>{
            localStorage.setItem("tokenRobot", res?.data?.Acces_Token);
          })
        axios
          .post(`${import.meta.env.VITE_API_GEOPROD_URL}login`, {}, config)
          .then((response) => {
            localStorage.setItem("token", response?.data?.Acces_Token);
            localStorage.setItem("id_user", response?.data?.id_user);
            localStorage.setItem("api_key", partnership_id);

            if (ref) {
              axios
                .get(
                  `${
                    import.meta.env.VITE_API_URL_AS
                  }/session_url/get_object_from_session_url/${ref}`
                )
                .then((res) => {
                  if (!res?.data?.error) {
                    const dumpJson = JSON.parse(res?.data);
                    if (
                      !dayjs(dumpJson?.submited_date, "DD/MM/YYYY")
                        .add(2, "month")
                        .isAfter(dayjs())
                    ) {
                      window.location.href = `/expired?partnership_id=${partnership_id}`;
                      return;
                    }
                    sessionStorage.setItem("id_opp", dumpJson?.id_opp);
                    setGlobalData(dumpJson?.globalData);
                    setValue(dumpJson?.value);
                    setPrime_commerciale_ttc(dumpJson?.prime_commerciale_ttc);
                    setGarantiesAutorise(dumpJson?.garantiesAutorise);
                    setActivities(dumpJson?.activities);
                    setCurrent(dumpJson?.step);
                    setActivitiesSecondaire(dumpJson?.activitiesSecondaire);
                    setGaranties_facultatives(dumpJson?.garanties_facultatives);
                    setGaranties_obligatoires(dumpJson?.garanties_obligatoires);
                    setInitGaranties({
                      garanties_facultatives: dumpJson?.garanties_facultatives,
                      prime_commerciale_ttc: dumpJson?.prime_commerciale_ttc,
                      garanties_obligatoires: dumpJson?.garanties_obligatoires,
                    });
                    const temp = {
                      code_naf: dumpJson?.globalData?.code_naf,
                      chiffre_affaire: parseFloat(
                        dumpJson?.globalData?.chiffre_affaire
                      ),
                      commission_tarificateur_autres_gar:
                        dumpJson?.globalData?.commission,
                      garanties: {
                        "PE suite à incendie ou DDE":
                          dumpJson?.globalData?.garanties[
                            "PE suite à incendie ou DDE"
                          ],
                        "PE suite à Vol ou Vandalisme":
                          dumpJson?.globalData?.garanties[
                            "PE suite à Vol ou Vandalisme"
                          ],
                        "Perte de Valeur du Fonds":
                          dumpJson?.globalData?.garanties[
                            "Perte de Valeur du Fonds"
                          ],
                        "Garantie Vol":
                          dumpJson?.globalData?.garanties["Garantie Vol"],
                        "Marchandises en Frigo":
                          dumpJson?.globalData?.garanties[
                            "Marchandises en Frigo"
                          ],
                        "Garantie Bris de Machines":
                          dumpJson?.globalData?.garanties[
                            "Garantie Bris de Machines"
                          ],
                        "Marchandises matériels transportés":
                          dumpJson?.globalData?.garanties[
                            "Marchandises matériels transportés"
                          ],
                        "Dommages électriques":
                          dumpJson?.globalData?.garanties[
                            "Dommages électriques"
                          ],
                        "Extension extérieurs":
                          dumpJson?.globalData?.garanties[
                            "Extension extérieurs"
                          ],
                        "Protection juridique":
                          dumpJson?.globalData?.garanties[
                            "Protection juridique"
                          ],
                      },
                      franchise: parseFloat(dumpJson?.globalData?.franchise),
                      code_activite_principale:
                        dumpJson?.globalData?.code_activite_principale,
                      code_activite_secondaire:
                        dumpJson?.globalData?.code_activite_secondaire,
                      capitaux_Degat_des_eaux: parseFloat(
                        dumpJson?.globalData?.capitaux_Degat_des_eaux
                      ),
                      capitaux_bris_de_glace: parseFloat(
                        dumpJson?.globalData?.capitaux_bris_de_glace
                      ),
                      surface_locaux: parseFloat(
                        dumpJson?.globalData?.surface_locaux
                      ),
                      valeur_metre_carre: parseFloat(
                        dumpJson?.globalData?.valeur_metre_carre
                      ),
                      contenu_pro: parseFloat(
                        dumpJson?.globalData?.contenu_pro
                      ),
                      capitaux_vol: dumpJson?.globalData?.capitaux_vol
                        ? parseFloat(dumpJson?.globalData?.capitaux_vol)
                        : 0,
                      capitaux_frigo: dumpJson?.globalData?.capitaux_frigo
                        ? parseFloat(dumpJson?.globalData?.capitaux_frigo)
                        : 0,
                      capitaux_machine: dumpJson?.globalData?.capitaux_machine
                        ? parseFloat(dumpJson?.globalData?.capitaux_machine)
                        : 0,
                      capitaux_transport: dumpJson?.globalData
                        ?.capitaux_transport
                        ? parseFloat(dumpJson?.globalData?.capitaux_transport)
                        : 0,
                      capitaux_extension: dumpJson?.globalData
                        ?.capitaux_extension
                        ? parseFloat(dumpJson?.globalData?.capitaux_extension)
                        : 0,
                      capitaux_dommage: dumpJson?.globalData?.capitaux_dommage
                        ? parseFloat(dumpJson?.globalData?.capitaux_dommage)
                        : 0,
                    };

                    setInitValues(temp);
                    setValidRef(true);
                    setGlobalLoading(false);
                    let oppDataJson = {
                      NUM_SIRET_1: globalData?.NUM_SIRET_1,
                      Pays: globalData?.Pays,
                      adresse_siege: globalData?.adresse_siege,
                      chiffre_affaire: globalData?.chiffre_affaire,
                      code_act_fact: globalData?.code_act_fact,
                      code_activite_principale:
                        globalData?.code_activite_principale,
                      code_activite_secondaire:
                        globalData?.code_activite_secondaire,
                      code_naf: globalData?.code_naf,
                      code_postal: globalData?.code_postal,
                      complement_adresse: globalData?.complement_adresse,
                      date_effet: globalData?.date_effet,
                      en_qualite_de: globalData?.en_qualite_de,
                      form_juridique: globalData?.form_juridique,
                      label_naf: globalData?.label_naf,
                      nom: globalData?.nom,
                      prenom: globalData?.prenom,
                      raison_sociale: globalData?.raison_sociale,
                      rcs: globalData?.rcs,
                      representant: globalData?.representant,
                      siren: globalData?.siren,
                      souscripteur_email: globalData?.souscripteur_email,
                      téléphone: globalData?.téléphone,
                      ville: globalData?.ville,
                      _adresse: globalData?._adresse,
                      _code_postal: globalData?._code_postal,
                      _ville: globalData?._ville,
                      QUALITE_OCCUPANT_2:globalData?.QUALITE_OCCUPANT_2
                    };

                    setOppData({ ...oppDataJson });
                    setOppDataCompare({ ...oppDataJson });
                  }
                })
                .catch((error) => {
                  const redirectURL = window.location.href.split("&ref");
                  localStorage.clear();
                  sessionStorage.clear();
                  window.location = redirectURL[0];
                  return error;
                });
            } else {
              setGlobalLoading(false);
            }
          })
          .catch((err) => {
            notification.error({
              message: "Erreur",
              duration: 4,
              closable: false,
              description: "Veuillez vous connecter s'il vous plait",
            });

            setTimeout(() => {
              window.location.href = `${
                import.meta.env.VITE_API_DISTRIBUTEUR_APIURL
              }/#/sign-in`;
            }, [3000]);
          });
      } else {
        notification.error({
          message: "Erreur",
          duration: 4,
          closable: false,
          description: "Veuillez vous connecter s'il vous plait",
        });
        window.location.href = `${
          import.meta.env.VITE_API_DISTRIBUTEUR_APIURL
        }/#/sign-in`;
      }
    }
  }, [ref, partnership_id]);
  return (
    <GlobalContext.Provider
      value={{
        ref,
        validRef,
        changed,
        globalData,
        docs,
        setDocs,
        setGlobalData,
        activities,
        setActivities,
        activitiesSecondaire,
        value,
        setValue,
        setActivitiesSecondaire,
        prime_commerciale_ttc,
        setPrime_commerciale_ttc,
        payementType,
        setPayementType,
        garantiesAutorise,
        setGarantiesAutorise,
        globalLoading,
        garanties_obligatoires,
        setGaranties_obligatoires,
        garanties_facultatives,
        setGaranties_facultatives,
        initValues,
        initGaranties,
        partnership_id,
        current,
        setCurrent,
        oppDataCompare,
        setOppDataCompare,
        oppData,
        setOppData,
        tarifRef,
        setTarifRef,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
